@import 'tailwindcss/utilities';
@import "colors";

html {
  scroll-behavior: smooth;
}


.ds-text-color {
  color: $dsVioletColor;
}

.ds-text-color:hover {
  color: $dsVioletHoverColor;
}


.ds-input-text {
  color: $dsTextColor;
  font-size: 14px;
  font-weight: normal;
}

.ds-text-lighter {
  color: #455560;
  font-size: 14px;
  font-weight: lighter;
}

.ds-option {
  background-color: white;
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 4px;
  overflow: auto;
  --tw-ring-opacity: 0.05;
  position: absolute;
  z-index: 50;
  width: 100%;
}

.ds-option:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ds-hover-color {
  background-color: $dsHoverColor;
}

.ds-hover-color:hover {
  background-color: $dsHoverColor;
}

.ds-border-blue {
  border: 1px solid $dsBorderBlueColor;
  border-radius: 12px;
}

.ds-border-gray {
  border: 1px solid $dsBorderGrayColor;
  border-radius: 3rem;
}

.ds-sidebar-text {
  color: $dsSidePanelTextColor;
}

.ds-range-input {
  background-color: $dsButtonBlueColor;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 0.5rem; /* 8px */
  cursor: pointer;
}

.ds-text-page-title {
  font-weight: bold;
  font-size: 1.5rem; /* 24px */
  //color: $dsVioletColor
  color: black;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.ds-text-page-sub-title {
  font-weight: bold;
  font-size: 1rem; /* 16px */
  color: $dsVioletColor;
}

.ds-image {
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
}

.ds-tooltip {
  color: $dsMainBackground;
  width: 10px;
  height: 10px;
}

.ds-link {
  color: $dsButtonBlueColor;
  text-decoration-line: underline;
}

.ds-link:hover {
  color: #3b71ca;
}

.ds-text-color-violet {
  font-weight: bold;
  color: $dsVioletColor;
}


.ds-background-color-violet {
  background-color: $dsVioletColor;
}

.ds-icon-gray {
  color: #666666;
}

.ds-gray-background {
  border-radius: 0.5rem;
  background-color: $dsGrayColor;
}

//.ds-gray-background:hover {
//  background-color: $dsHoverColor;
//}

.ds-white-background {
  border-radius: 0.5rem;
  background-color: white;
}

.ds-block-title {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.ds-spacing-4 {
  gap: 1rem;
}

.ds-spacing-2 {
  gap: 0.5rem;
}

.ds-margin-4 {
  margin: 1rem;
}

.ds-margin-2 {
  margin: 0.5rem;
}

.ds-text-hyperlink {
  color: $dsButtonBlueColor;
  text-decoration-line: underline;
  font-weight: bold;
}

.ds-violet-border {
  outline: none;
}

.ds-violet-border:hover,
.ds-violet-border:focus {
  border-color: $dsVioletColor;
  box-shadow: none;
}

.ds-bg-stick {
  position: fixed;
  top: 64px;
  height: 108px;
  background-color: $dsMainBackground;
  width: calc(100% - 288px);
  z-index: 30;
}

.ds-sticky {
  position: fixed;
  top: 88px;
  width: calc(100% - 288px);
  z-index: 60;
  background-color: $dsMainBackground;
}

.ds-toggle-box {
  position: relative;
  width: fit-content;

  .toggle-box-wrapper {
    position: absolute;
    top: calc(100% + 5px);
    min-width: 200px;
    border-width: 1px;
    border-radius: 0.5rem;
    background-color: $white;
    z-index: 999;
    box-sizing: border-box;
    width: inherit;

    &.left {
      left: 0;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }

    &.right {
      right: 0;
    }
  }
}

.ds-icon {
  width: 15px;
  height: 15px;
}

.ds-button-cancel {
  padding: 8px;
  color: black;
  border-radius: 8px;
  background-color: #E2E8F0;
  font-weight: 600;
  cursor: pointer;
}

.ds-button-delete {
  padding: 8px;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  background-color: rgb(220 38 38);
  cursor: pointer;
}

.ds-button-buy {
  //bg-purple-800 text-white font-medium py-2 px-4 rounded-full flex items-center space-x-6
  background-color: rgb(107 33 168);
  color: white;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

//w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition duration-300
.ds-button-create-report {
  width: 100%;
  background: linear-gradient(to right, #a855f7, #ec4899);
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.ds-button-create-report-2 {
  background: linear-gradient(to right, #a855f7, #ec4899);
  color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.ds-button-create-report:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.ds-font-family {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.ds-block-no-hover {
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  background-color: white;
  border-color: $dsVioletColor;
  border-width: 1px;
  align-items: center;
  justify-content: center;
}

.ds-block-2 {
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  background-color: white;
  border-color: rgb(209 213 219);
  border-width: 1px;
  align-items: center;
  justify-content: center;
}

.multi-select-box-border {
  display: inline-flex;
  align-items: center;
  margin-left: 0.25rem;
  border-radius: 16px;
  border-color: $dsVioletHoverColor;
  padding-left: 4px;
  padding-right: 4px;
  border-width: 1px;
}

.ds-text-size-small {
  font-size: 12px;
}

.ds-text-page-title-2 {
  font-weight: bold;
  font-size: 16px;
}

.ds-tab-text {
  color: $dsVioletColor;
  border-color: $dsVioletColor;
}

.ds-tab-text:hover {
  color: $dsVioletFocusColor;
  border-color: $dsVioletFocusColor;
}

.ds-button-cancel:hover {
  background-color: rgb(221 214 254);
}

.ds-text-box-label {
  font-weight: 600;
  font-size: 12px;
  color:rgb(55 65 81);
}
