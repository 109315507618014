@import "assets/scss/colors";

#side-panel-wrapper {
  #side-pannel {
    background-color: $white;
    box-shadow: 0px 0px 0.6px #64748b;
    color: hsl(207.2, 100%, 10.4%);
    font-weight: 500;

    .group {
      &:hover {
        color: $dsVioletColor;
      }
    }

    .active {
      background-color: $dsVioletBackgroundColor;
      color: $dsVioletColor;
    }
  }
}
