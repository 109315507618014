@import "assets/scss/colors";

#app-header {
  //box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
  background-color: white;
  height: 64px;
  align-content: center;
  position: fixed;
  left: 0;
  right: 0;
}
