// Tooltip Container
.tooltipContainer {
  display: block;
  padding-left: 6px;
  width: 12px;
  position: relative;
  cursor: pointer;
}

// Tooltip Text Container
.copyTooltip {
  position: absolute;
  opacity: 0;
  top: -10px;
  right: -238px;
  background-color: #6794F9;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  text-transform: none;
  transition: all 0.75s ease;
  width: 190px;
}

.copyTooltip::after {
  position: absolute;
  top: 15px;
  left: 0px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #6794F9;
  content: "";
  font-size: 0;
  line-height: 0;
  margin-left: -5px;
  width: 0;
}

.copyTooltip.active {
  opacity: 1;
}

.tooltipText {
  visibility: hidden;
  background-color: rgb(47, 47, 47);
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: relative;
  z-index: 1;
  top: -30px;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
