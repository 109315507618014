$darkIndigo: #220D4E;
$white: white;
$softPeach: #ECEDF3;
$amethystSmoke: #A397B4;
$azul: #2563EB;
$porcelain: #EFF2F3;
$mulledWine: #4E4562;
$bluish: #2976BB;
$congressBlue: #02478E;
$brandeisBlue: #0070FF;
$ultramarineBlue: #516cf5;
$antiFlashWhite: #f3f3f7;
$independence: #43425D;
$blackCoral: #565a71;
$shadow: #0000000A;
$babyPowder: #FFFFFC;
$geranium: #DE1E25;
$linkWater: #D9E4F5;
$lightGray: #d2d5d8;
$brightGray: #EDEDE9;
$chineseSilver: #ccc;
$iron: #ced4da;
$softPeach: #ECEDF2;
$riverBed: #434C59;
$alabaster: #FAFAFA;
$whiteSmoke: #F5F5F5;
$porcelain: #EFF2F3;
$greenWhite: #e9e9ed;
$gravel: #43474f;
$romance: #f3f6fC;
$snowDrift: #F7FAF7;
$iron: #d4d9dd;
$dogwoodRose: #E0216A;
$blueViolet: #8A2BE2;
$fuelYellow: #ECA927;
$greenTeal: #0CB577;
$dsMainBackground: #FFFFFF;
$dsBorderBlueColor: #91CAFF;
$dsBorderGrayColor: rgb(240, 240, 240);
$dsBorderDashedGrayColor: rgb(177, 175, 175);
$dsButtonBlueColor: #3E79F7;
$dsHoverColor: #F5F3FF;
$dsTextColor: #455560;
$dsVioletColor: #8b5cf6;
$dsVioletHoverColor: #c4b5fd;
$dsVioletButtonHoverColor: #F5F3FF;
$dsVioletFocusColor: #6d28d9;
$dsVioletBackgroundColor: #f5f3ff;
$dsGrayColor: #FAFAFA;
$dsSidePanelTextColor: #5A6872;
$dsTableBorderColor: #e0e0e0;
