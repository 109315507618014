@import "assets/scss/colors";

.tooltip-box {
  position: relative;
  width: fit-content;

  .tooltip-box-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    min-width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 999;
    box-sizing: border-box;
    background-color: $dsMainBackground;
    display: flex; /* Ensure content is centered */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */

    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 12px;
      height: 12px;
      background-color: $dsMainBackground;
      z-index: 998;
    }
  }
}
