.ds-search-box {
  padding: 0.4rem 0.75rem;
  height: 40px;
  border-color: $dsBorderGrayColor;
}

.ds-search-box:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ds-input {
  border-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
  border-color: $dsBorderGrayColor;
  border-width: 0.5px;
  background-color: white;
  transition: background-color 0.2s ease-in-out;
}

.ds-input:hover {
  background-color: $dsVioletButtonHoverColor;
}

.ds-input:focus {
  border-color: $dsVioletButtonHoverColor;
  border-radius: 0.5rem;
  border-style: solid;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input {
  &.ds-input {
    height: 35px;
  }
}

.ds-text-form-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: rgb(17 24 39);
}

.ds-text-form-des {
  font-size: 0.875rem;
  color: rgb(107 114 128);
  line-height: 1.5rem;
  max-width: 42rem;
}
