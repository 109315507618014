.ds-block {
  border-radius: 0.5rem;
  padding: 2rem 2rem;
  border-width: 0.5px;
  border-color: $dsBorderGrayColor;
  background-color: $dsGrayColor;
  box-shadow: #5A6872;
}

.ds-block-modal {
  border-radius: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-width: 0.5px;
  border-color: $dsBorderGrayColor;
  background-color: $dsGrayColor;
  box-shadow: #5A6872;
}

.ds-block-with-hover {
  padding: 0.375rem 0.75rem;
  background-color: $dsGrayColor;
  transition: box-shadow 0.5s;
  border-radius: 0.5rem;
  border-width: 0.5px;
  border-color: $dsBorderGrayColor;
  box-shadow: #5A6872;
}

.ds-block-with-hover:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: box-shadow 0.3s;
}

.ds-block-dashed {
  border-radius: 10px;
  padding: 0.375rem 0.75rem;
  border-color: $dsBorderGrayColor;
  border-style: dashed;
  border-width: 2px;
  text-align: center;
  outline: dashed $dsBorderDashedGrayColor;
  transition: box-shadow 0.5s;
}

.ds-block-dashed:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: box-shadow 0.3s;
}

.ds-tab-color {
  color: $dsVioletColor;
  border-color: $dsVioletColor;
}

.ds-tab-color:hover {
  opacity: 0.7
}

.grid-container-wrapper {
  position: relative;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  gap: 10px;
}

.grid-item {
  background-color: #fafafa;
  height: 60px;
  border-radius: 4px;
}

.ds-block-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dsGrayColor;
  border-radius: 4px;
  z-index: 1;
  position: absolute;
}

.ds-block-card-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dsGrayColor;
  border-radius: 4px;
  border: 2px solid $dsVioletColor;
  z-index: 1;
  position: absolute;
}

.resize-handle-bottom-right {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
  z-index: 10;
}

.resize-handle-bottom-left {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: nesw-resize;
  z-index: 10;
}

.resize-handle-top-right {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: nesw-resize;
  z-index: 10;
}

.resize-handle-top-left {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: nwse-resize;
  z-index: 10;
}

.resize-handle-left {
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: ew-resize;
  transform: translateX(-50%);
  z-index: 2;
}

.resize-handle-right {
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: ew-resize;
  transform: translateX(50%);
  z-index: 2;
}

.resize-handle-top {
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: ns-resize;
  transform: translateY(-50%);
  z-index: 2;
}

.resize-handle-bottom {
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: ns-resize;
  transform: translateY(50%);
  z-index: 2;
}

.move-handle {
  top: 0;
  right: 0;
  position: absolute;
  cursor: move;
}
