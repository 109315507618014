@import 'tailwindcss/base';
@import "colors";
@import "text";

.ellipsis {
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Shows ellipsis for truncated text */
}


