@import "assets/scss/colors";

#homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  background-color: white;
  padding-bottom: 1.25rem;
  padding-top: 75px;
  //min-height: 120vh;
  min-height: calc(120vh - 75px);
}

.app-1 {
  font-size: 15px;
  align-items: center;
  border-radius: 0.375rem;
  color: black;
  background: white;
  padding-left: 4px;
  border: 1px solid $dsBorderBlueColor;
  cursor: pointer;
}

.app-background {
  background-color: #FAFAFA;
}
