.ds-button {
  background-color: $dsVioletColor;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 0.5rem; /* 8px */
}

.ds-button:hover {
  opacity: 0.7;
}

.ds-button-delete {
  background-color: rgb(220, 38, 38);
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 0.5rem; /* 8px */
}

.ds-button-color {
  background-color: $dsButtonBlueColor;
}

.ds-button-color:hover {
  opacity: 0.7;
}

.ds-button-transparent {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 0.5rem; /* 8px */
}

.ds-button-transparent:hover {
  opacity: 0.4;
}

.ds-button-with-border {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 0.5rem; /* 8px */
  transition: box-shadow 0.5s;
}

.ds-button-with-border:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: box-shadow 0.3s;
}

.ds-button-transparent-no-hover {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 0.5rem; /* 8px */
}

.ds-button-2 {
  background-color: transparent;
  font-weight: 400;
  text-align: center;
  border: 1px solid $dsVioletColor;
  color: $dsVioletColor;
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
  border-radius: 0.3rem;
  width: fit-content;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.ds-button-2:hover {
  background-color: rgb(248 250 252);
}

.ds-button-3 {
  background-color: $dsVioletColor;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 10px 18px;
  border-radius: 0.5rem; /* 8px */
  width: 100%;
}

.ds-button-3:hover {
  background-color: #a78bfa;
}

.ds-button-disable {
  background-color: transparent;
  font-weight: 400;
  text-align: center;
  border: 1px solid rgb(59 130 246);
  color: rgb(29 78 216);
  user-select: none;
  padding: 4px 8px;
  border-radius: 0.3rem;
  width: fit-content;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  cursor: not-allowed;
}

.ds-button-cancel {
  background-color: $dsHoverColor;
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  padding: 6px 18px;
  border-radius: 0.5rem; /* 8px */
}

.ds-button-icon {
  cursor: pointer;
}

.ds-button-icon:hover {
  opacity: 0.7;
}

.ds-button-card-type {
  background-color: white;
  color: $dsVioletColor;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  padding: 0;
  border-radius: 0.5rem;
  width: 120px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ds-button-card-type:hover {
  background-color: $dsHoverColor;
  color: $dsVioletColor;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  padding: 0;
  border-radius: 0.5rem;
  width: 120px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
}
